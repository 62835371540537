<template>
  <Card>
    <template #header>
      <StepCardHeader class="mb-2" />
    </template>
    <div>
      <div id="imports-details-table">
        <div class="flex filter-symbol">
          <DeliveryStatusUI type="rush" component="simple-text" />
          <DeliveryStatusUI type="priority" component="simple-text" />
          <DeliveryStatusUI type="refrigerated" component="simple-text" />
          <DeliveryStatusUI type="leave-at-door" component="simple-text" />
          <DeliveryStatusUI type="collect" component="simple-text" />
          <DeliveryStatusUI type="male-driver" component="simple-text" />
          <DeliveryStatusUI type="female-driver" component="simple-text" />
        </div>
        <MainTable
          ref="Table"
          :loading.sync="loading"
          :params="params"
          :checkedRows="checkedRows"
          @checkedResults="checkedResults"
          :reload="reload"
        />
      </div>
      <div class="flex f-jce mt-4">
        <Button
          class="lunch-break-btn"
          type="is-primary"
          :disabled="checkedRows.length <= 0 || loading"
          @click="onCreateCsv(checkedRows)"
          :permission="Permission.create"
        >
          {{ $t('schedule.createCSV') }}
        </Button>
      </div>
    </div>
  </Card>
</template>

<script>
import { toast } from '@/utils/dialog';
import { Card, StepCardHeader, Button, DeliveryStatusUI } from '@/components';
import MainTable from './MainTable.vue';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Card,
    MainTable,
    StepCardHeader,
    Button,
    DeliveryStatusUI
  },
  computed: {
    Permission() {
      return Permissions.DeliveryInboxProcessCSV;
    }
  },
  data() {
    return {
      disabled: false,
      loading: true,
      params: { search_by: null },
      checkedRows: [],
      reload: false
    };
  },
  methods: {
    checkedResults(value) {
      this.checkedRows = value;
    },

    async onCreateCsv() {
      this.loading = true;
      let ids = [];

      this.checkedRows.map((e) => {
        ids = [...ids, e.id];
      });

      console.log(ids);

      try {
        await this.Api.post('/deliveries_inbox/process_csv', {
          deliveries_inbox_ids: ids
        });

        this.reload = !this.reload;
        this.checkedRows = [];

        toast('success', this.$t('schedule.successfullyDailyReports'), 5000);
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: calc(100% - 100px)
  #imports-details-table
    width: 100%
    margin-right: 15px
    .filter-symbol
      font-size: 14px
      margin: 3.5rem 0 1.5rem 0
      div
        margin-right: 1.2rem
        ::v-deep
          .icon
            margin-right: 0 !important
      .symbol
        width: 16px
    .filters
      margin-left: 90px
      .search-field
        width: 50%
      .alert-container
        min-width:290px
        background: $red-100
        border-left: 4px solid $red-800
        border-radius: 4px 4px 4px 4px
      .icon, .message
        margin: 8px 4px
      .icon
        color: $red-800
        margin-left: 12px
      .message
        background: $red-100
        color: $red-800
        margin-right: 8px
  .priority
    height: 20px
  ::v-deep
    .priority-icon
      display: flex
      align-items: center
      color: $green-700
    .card-header
      position: absolute
      top: .5rem
      border: none
    .card-content,
    .card-content > .flex
      height: 100%
</style>
