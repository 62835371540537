<template>
  <Card>
    <div class="flex">
      <div id="imports-table">
        <div class="filters flex f-jce">
          <DatePicker
            class="datepicker"
            v-model="params.date"
            icon-right="calendar-today"
            :disabled="loading"
            position="is-bottom-left"
          />
        </div>
        <MainTable ref="Table" :loading.sync="loading" :params="params" />
      </div>
    </div>
  </Card>
</template>

<script>
import { Card, DatePicker } from '@/components';
import MainTable from './MainTable.vue';

export default {
  components: {
    Card,
    DatePicker,
    MainTable
  },
  created() {
    this.updateSearchParams(this.paramsFromUrl());
  },
  data() {
    return {
      disabled: false,
      loading: false,
      params: { date: null }
    };
  },
  methods: {
    paramsFromUrl() {
      let query = this.$route.query;
      if (Object.keys(query).length === 0) {
        query = { date: this.$moment(new Date()).format('Y-MM-DD') };
      }
      return query.date;
    },
    updateRouter(query) {
      this.$router.replace({ query });
    },
    updateSearchParams(URLParams) {
      if (URLParams) this.params.date = URLParams;
    }
  },
  watch: {
    'params.date'(value) {
      if (this.$route.query.date !== value) this.updateRouter({ date: value });
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: calc(100% - 100px)
  #imports-table
    width: 100%
    .filters
      position: relative
      z-index: 10
      .datepicker
        margin-bottom: 0
  ::v-deep
    .card-content,
    .card-content > .flex
      height: 100%
</style>
