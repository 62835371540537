<template>
  <section>
    <UploadCSVModal :isOpen.sync="modals.uploadCSVModalOpen" />
    <Portal to="headerActions">
      <div class="flex">
        <Link
          to="/schedule/imports/create-csv"
          isRouter
          class="mr-2"
          :permission="Permission.CSV.create"
        >
          <Button type="is-primary" :label="$t('schedule.linkCreateSVG')" icon-left="note-text" />
        </Link>
        <Button
          type="is-primary"
          :label="$t('schedule.uploadCSV')"
          icon-left="upload"
          @click="openUploadModal"
          v-if="hasPermission"
        />
      </div>
    </Portal>
    <Card id="imports" />
  </section>
</template>

<script>
import Card from '@/components/Imports/Card.vue';
import { Button, Link } from '@/components';
import UploadCSVModal from './UploadCSVModal';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    Card,
    UploadCSVModal,
    Link
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  data() {
    return {
      file: null,
      modals: { uploadCSVModalOpen: false },
      fileName: ''
    };
  },
  computed: {
    breadcumbs() {
      return ['menu.imp'];
    },
    Permission() {
      return { Imports: Permissions.Imports, CSV: Permissions.DeliveryInboxProcessCSV };
    },
    hasPermission() {
      let isAllowed = false;
      if (Permissions.DeliveryInboxProcessCSV.create?.length) {
        isAllowed = Permissions.DeliveryInboxProcessCSV.create.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }

      return (
        isAllowed || this.Secure.permissionValidator(Permissions.DeliveryInboxProcessCSV.create)
      );
    }
  },
  methods: {
    openUploadModal() {
      this.modals.uploadCSVModalOpen = true;
    }
  }
};
</script>
<style lang="sass" scoped>
#imports
  margin: 0
</style>
