<template>
  <section>
    <Card id="createcsv" />
  </section>
</template>

<script>
import Card from '@/components/Imports/CreateCSV/Card.vue';

export default {
  components: {
    Card
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  computed: {
    breadcumbs() {
      return ['menu.inb'];
    }
  }
};
</script>
