<template>
  <div id="schedule-imports-create-table">
    <Table
      :checkedRows="checkedRows"
      :focusable="true"
      :loading.sync="oLoading.table"
      :reLoad="reload"
      :show-detail-icon="false"
      @checkedResults="$emit('checkedResults', $event)"
      apiUrl="deliveries_inbox"
      class="imports-table"
      paginated
      ref="table"
    >
      <b-table-column :label="$t('global.orderNumber')" v-slot="props" :td-attrs="tdAttrs">
        <div class="flex f-wrap">
          <DeliveryIcons
            class="ml-2 mr-0"
            :status="{
              rush: props.row.rush,
              priority: props.row.priority,
              refrigerated: props.row.refrigeration_needed,
              leaveAtDoor: props.row.leave_at_door,
              collect: props.row.is_collect_event,
              male_driver: props.row.male_driver,
              female_driver: props.row.female_driver
            }"
          />

          <span> {{ props.row.tracking_number || '-' }} </span>
        </div>
        <CopaymentTag :value="props.row.copayment_amount" />
      </b-table-column>
      <b-table-column :label="$t('global.date')" v-slot="props" :td-attrs="tdAttrs">
        <DateTime class="f-jc" :date="props.row.delivery_date" format="MM/DD/Y" />
        <ConstraintDatesViewer
          isTag
          :startTime="props.row.delivery_start_time"
          :endTime="props.row.delivery_end_time"
        />
      </b-table-column>
      <b-table-column :label="$t('participant.clientCode')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.client_code || '-' }}
      </b-table-column>
      <b-table-column
        :label="$t('participant.name')"
        v-slot="props"
        :td-attrs="tdAttrs"
        width="200"
      >
        {{ props.row.full_name || '-' }}
      </b-table-column>

      <b-table-column :label="$t('participant.address')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.full_address || '-' }}
      </b-table-column>

      <b-table-column :label="$t('contact.phone') + ' #'" v-slot="props" :td-attrs="tdAttrs">
        <Phone-viewer :phone="props.row.mobile_phone_number || props.row.phone_number" />
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import Table from './TableCSV.vue';
import {
  PhoneViewer,
  DateTime,
  ConstraintDatesViewer,
  DeliveryIcons,
  CopaymentTag
} from '@/components';

export default {
  components: {
    PhoneViewer,
    Table,
    DateTime,
    ConstraintDatesViewer,
    DeliveryIcons,
    CopaymentTag
  },
  data() {
    return {
      oLoading: { table: this.loading, rowId: 0 },
      manifest: null,
      unsubscribe: null
    };
  },
  computed: {
    group_id() {
      return this.$route.path.split('/')[3];
    },
    bTable() {
      return this.$refs?.table?.bTable;
    }
  },
  methods: {
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    }
  },
  watch: {
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    }
  },
  props: {
    reload: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => {}
    },
    checkedRows: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="sass" scoped>
#schedule-imports-create-table
  border-radius: $br-md
  .table-container
    min-height: calc(100% - 120px)
.imports-table
  ::v-deep
    .b-table .table-wrapper .table tbody tr
      td:nth-child(2)
        padding-right: 0
      td:nth-child(3)
        padding-left: 0.5rem
    .is-selected
      cursor: default
      .action-buttons
          visibility: visible
    th
      z-index: 39!important
      vertical-align: middle !important
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
    &.isOpen
      .action-buttons
        visibility: visible
    .table-wrapper
      margin-bottom: 0.5rem
      height: calc(100vh - 330px)
    .is-hidden-xl
      display: table-cell
  .action-buttons
    visibility: hidden
.w-100
  width: 100px
.w-200
  width: 200px
.w-250
  width: 250px
</style>
