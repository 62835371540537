<template>
  <div class="table-container">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
    <div v-if="header" class="table-title">{{ header }}</div>
    <template v-if="$slots.empty && isEmpty">
      <slot name="empty" />
    </template>
    <b-table
      :class="classes"
      :data="tableData"
      :focusable="focusable"
      :loading="false"
      :selected="selected"
      :sticky-header="true"
      :total="pagination.total"
      @check-all="handleCheckAll"
      @update:checkedRows="handleDeselect"
      backend-pagination
      checkable
      class="base-table"
      paginated
      pagination-size="is-small"
      ref="bTable"
      striped
      v-bind="{ ...$props, ...$attrs }"
      v-else
    >
      <slot />
      <template #empty>
        <Empty v-if="!isLoading" />
      </template>
      <template slot="detail" slot-scope="props">
        <slot name="details" v-bind="{ row: props.row }" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { Empty } from '@/components';
export default {
  components: {
    Empty
  },
  mounted() {
    this.getData();

    if (this.updateInterval) {
      let { updateInterval } = this;
      if (updateInterval < 1000) updateInterval = updateInterval * 1000;
      this.interval = setInterval(() => {
        this.getData();
      }, updateInterval);
    }

    this.$watch(
      () => {
        return this.$refs.bTable?.checkedRows;
      },
      (value) => {
        this.$emit('checkedResults', value);
      }
    );
  },
  destroyed() {
    const { bTable } = this;
    clearInterval(this.interval);
    if (bTable?.$el.children[0])
      bTable?.$el.children[0].removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      selected: null,
      timeout: null,
      interval: null,
      cancellToken: null,
      scrollTimeout: 0,
      isLoading: this.loading,
      pagination: { total: 0, perPage: this.perPage, currentPage: 1 },
      tableData: this.data,
      checkAll: false,
      oldRows: []
    };
  },
  computed: {
    classes() {
      let classes = [];
      if (this.$listeners.select) classes.push('is-selectable');
      classes.push(this.isEmpty ? 'is-empty' : 'no-empty');
      return classes.join(' ');
    },
    isEmpty() {
      return !this.tableData.length && !this.loading;
    },
    bTable() {
      return this.$refs?.bTable;
    }
  },

  methods: {
    handleCheckAll() {
      this.checkAll = !this.checkAll;

      const table = this.$refs.bTable;

      while (table.checkedRows.length > 0) {
        table.checkedRows.pop();
      }

      if (this.checkAll) {
        table.checkedRows.push(...table.newData);

        this.oldRows = table.checkedRows.length;
      } else {
        this.oldRows = 0;
      }
    },

    handleDeselect(e) {
      if (this.oldRows - e.length === 1 || this.oldRows - e.length === -1) {
        this.$emit('checkedResults', e);
        this.oldRows = e.length;
      }
    },

    handleScroll(e) {
      clearTimeout(this.scrollTimeout);
      const { scrollHeight, offsetHeight, scrollTop } = e.target;
      const scroll = scrollTop + offsetHeight;
      this.scrollTimeout = setTimeout(() => {
        if (scroll > scrollHeight - 200) this.nextPage();
      }, 300);
    },

    nextPage() {
      const hasReachTotal = this.tableData.length >= this.pagination.total;
      if (hasReachTotal || this.isLoading) return;
      this.pagination.currentPage += 1;
      this.onPageChange(this.pagination.currentPage);
    },

    onPageChange(page) {
      this.$emit('page-change', page);
      this.pagination.currentPage = page;
      this.getTableData();
    },

    // onSelect(item) {
    //   if (this.$listeners.select) {
    //     this.selected = item;
    //     this.$emit('select', item);
    //   }
    // },

    // removeRow({ key, value }) {
    //   this.tableData = this.tableData.filter((row) => row[key] != value);
    // },

    async getData(clear) {
      clearTimeout(this.timeout);
      this.cancelToken = this.Api.cancelToken;
      this.timeout = setTimeout(async () => {
        await this.getTotal();
        await this.getTableData();
        if (clear) {
          this.tableData = [];
          // this.onSelect({});
        }

        this.handleCheckAll();
      }, 50);
    },

    reload() {
      this.getTableData();
      this.oldRows = 0;
    },

    async getTableData() {
      this.isLoading = true;
      try {
        const { data } = await this.Api.get(`${this.apiUrl}?per_page=99999`, {
          cancelToken: this.cancelToken.token
        });

        this.tableData = data;
        // this.onSelect({});
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },

    async getTotal() {
      try {
        const { data } = await this.Api.get(`/${this.apiUrl}/total_items`, {
          cancelToken: this.cancelToken.token
        });
        this.pagination.total = ((data && data[0]) || data).total_items;
      } catch (error) {
        console.error(error);
      }
    }
  },

  watch: {
    apiUrl() {
      this.reload();
    },
    loading(value) {
      this.isLoading = value;
    },
    isLoading(value) {
      this.$emit('update:loading', value);
    },
    reLoad() {
      this.reload();
      this.getTotal();
    }
  },

  props: {
    apiUrl: { type: String, required: true },
    data: { type: Array, default: () => [] },
    focusable: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    paginated: { type: Boolean, default: false },
    perPage: { type: Number, default: 15 },
    header: { type: String, default: '' },
    updateInterval: { type: Number, default: 0 },
    checkable: { type: Boolean, default: false },
    reLoad: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.table-container
  position: relative
  overflow: visible
  height: 100%
  z-index: 1
  .table-title
    font-weight: bold
    font-size: $f-xxl
    padding-bottom: 5px
    margin-bottom: 12px
    border-bottom: 1px solid $gray-300
.base-table
  display: flex
  flex-flow: column
  height: 100%
  ::v-deep &.no-empty .table-wrapper table
    height: auto
  &.is-selectable
    cursor: pointer
  .inactive
    opacity: .3
  ::v-deep
    &.is-empty td
      height: calc(100vh - 500px)
    &:hover
      tr,td
        transition: none
    th,
    td
      border: none
      &:first-child
        border-radius: $br-md 0 0 $br-md
      &:last-child
        border-radius: 0 $br-md $br-md 0
    .table-wrapper
      height: calc(100% - 50px)
      table
        height: 100%
      tr.is-selected td
        border-color: transparent!important
      td
        vertical-align: middle
      .isOpen
        border-top: 1px solid $blue-400
        background: $blue-100
    .detail
      margin-bottom: 10px
      box-shadow: none!important
      background: $blue-100!important
      td
        border-radius: 0 0 $br-md $br-md!important
        border-bottom: 1px solid $blue-400
</style>
