var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"schedule-imports-create-table"}},[_c('Table',{ref:"table",staticClass:"imports-table",attrs:{"checkedRows":_vm.checkedRows,"focusable":true,"loading":_vm.oLoading.table,"reLoad":_vm.reload,"show-detail-icon":false,"apiUrl":"deliveries_inbox","paginated":""},on:{"update:loading":function($event){return _vm.$set(_vm.oLoading, "table", $event)},"checkedResults":function($event){return _vm.$emit('checkedResults', $event)}}},[_c('b-table-column',{attrs:{"label":_vm.$t('global.orderNumber'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"flex f-wrap"},[_c('DeliveryIcons',{staticClass:"ml-2 mr-0",attrs:{"status":{
            rush: props.row.rush,
            priority: props.row.priority,
            refrigerated: props.row.refrigeration_needed,
            leaveAtDoor: props.row.leave_at_door,
            collect: props.row.is_collect_event,
            male_driver: props.row.male_driver,
            female_driver: props.row.female_driver
          }}}),_c('span',[_vm._v(" "+_vm._s(props.row.tracking_number || '-')+" ")])],1),_c('CopaymentTag',{attrs:{"value":props.row.copayment_amount}})]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('global.date'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('DateTime',{staticClass:"f-jc",attrs:{"date":props.row.delivery_date,"format":"MM/DD/Y"}}),_c('ConstraintDatesViewer',{attrs:{"isTag":"","startTime":props.row.delivery_start_time,"endTime":props.row.delivery_end_time}})]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('participant.clientCode'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.client_code || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('participant.name'),"td-attrs":_vm.tdAttrs,"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.full_name || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('participant.address'),"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.full_address || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('contact.phone') + ' #',"td-attrs":_vm.tdAttrs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('Phone-viewer',{attrs:{"phone":props.row.mobile_phone_number || props.row.phone_number}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }